import { CardContentModel } from '../models';

export const buyerTriedTested: CardContentModel[] = [
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_suppliers.svg',
    title: 'Access to a wider selection of pre-approved suppliers',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_nothing_installed.svg',
    title: 'Nothing to install, no licence needed',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_nothing_commercial_platform.svg',
    title: 'Competitive and transparent commercial platform',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_nothing_prefered_suppliers.svg',
    title: 'Option to retain preferred local suppliers',
  },
];
