import { CardContentModel } from '../models';

export const buyerHowItWorks: CardContentModel[] = [
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_circle_specify.svg',
    title: 'Specify your print and design requirements',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_circle_choose.svg',
    title: 'Choose from a range of competitive prices',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_circle_receive_order.svg',
    title: 'Prepare to receive your order',
  },
];
