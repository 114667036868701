import React from 'react';

const Highlights = () => (
  <section>
    <header className="row text-center pt-6 pb-3">
      <div className="col">
        <h2>Print Marketplace highlights</h2>
      </div>
    </header>

    <section className="row bg--gray-light p-5 rounded align-items-center">
      <div className="col-md-6 col-lg-5 offset-lg-1 offset-md-0">
        <h3 className="h4">Quote comparison feature</h3>

        <ul>
          <li>Obtain live supplier quotes</li>
          <li>Customise your comparison listings</li>
          <li>
            Instant pricing shows most competitive rate as well as carbon impact
          </li>
        </ul>
      </div>
      <div className="col-md-6 col-lg-6 pt-4 pt-md-0">
        <img
          src="https://cdn-dev.print-marketplace.co.uk/frontend/highlights_quotes.png"
          alt="Compare now"
          className="img-fluid"
        />
      </div>
    </section>

    <section className="row bg--gray-light px-5 py-5 pb-lg-0 my-4 rounded align-items-center">
      <div className="col-md-6 pb-4 pb-md-0">
        <img
          src="https://cdn-dev.print-marketplace.co.uk/frontend/highlights_review_order.png"
          alt="Quote requests"
          className="img-fluid"
        />
      </div>
      <div className="col-md-6">
        <h3 className="h4">
          Visibility of order progress from print specification to delivery
        </h3>

        <ul>
          <li>Useful auto-save feature, to stay on track simply and quickly</li>
          <li>Instantly go to last saved progress</li>
          <li>Statuses refresh regularly to keep you up-to-date</li>
        </ul>
      </div>
    </section>

    <section className="row bg--gray-light p-5 rounded align-items-center">
      <div className="col-md-6 col-lg-5 offset-lg-1 offset-md-0">
        <h3 className="h4">Design services</h3>

        <ul>
          <li>Choose your way to design</li>
          <li>Bespoke design supported by our experts</li>
          <li>Create own design using our online tool</li>
          <li>Automated pre-flight artwork checks ensure accuracy</li>
        </ul>
      </div>
      <div className="col-md-6 col-lg-6 pt-4 pt-md-0">
        <img
          src="https://cdn-dev.print-marketplace.co.uk/frontend/highlights_design.png"
          alt="Design services"
          className="img-fluid"
        />
      </div>
    </section>

    <section className="row bg--gray-light px-5 py-5 pb-lg-0 my-4 rounded align-items-center">
      <div className="col-md-6 pb-4 pb-md-0">
        <img
          src="https://cdn-dev.print-marketplace.co.uk/frontend/highlights_myorders.png"
          alt="Retained order history"
          className="img-fluid"
        />
      </div>
      <div className="col-md-6">
        <h3 className="h4">Retained order history</h3>

        <ul>
          <li>Saves time on repeat purchases</li>
          <li>Easily manage budgets</li>
        </ul>
      </div>
    </section>
  </section>
);

export default Highlights;
