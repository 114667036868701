import * as React from 'react';

// Components
import { SkipNavLink } from '@reach/skip-nav';
import Benefits from '../components/benefits';
import Hero from '../components/hero';
import Highlights from '../components/highlights';
import HowItWorks from '../components/how-it-works';
import NavbarComponent from '../components/navbar';
import SEO from '../components/seo';
import TriedTested from '../components/tried-tested';
import Footer from '../components/footer';
import BuyerAccount from '../components/buyer-account';
import Awards from '../components/awards';

// Data
import { footerContent } from '../data/footer';
import footerPartnersCCSContent from '../data/footer-partners-ccs';
import { mainMenu } from '../data/menu';
import { loginMenu } from '../data/menu-login';
import { buyerBenefits } from '../data/buyer-benefits';
import { buyerHowItWorks } from '../data/buyer-how-it-works';
import { buyerTriedTested } from '../data/buyer-tried-tested';

// Layout
import Layout from '../layout/layout';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Print Marketplace - Compare printing prices from trusted suppliers"
      description="The simple way to save on public sector printing. Compare printing prices from trusted suppliers quickly and simply."
    />
    <SkipNavLink />

    <section
      className="container-fluid home-hero-bg pb-3 pb-md-0"
      style={{
        backgroundImage:
          'url("https://cdn-dev.print-marketplace.co.uk/frontend/home-hero-image.png")',
      }}
    >
      <div className="row">
        <div className="col-xl-12 col-md-12 mx-auto">
          <NavbarComponent
            current="buyer"
            mainMenu={mainMenu}
            loginMenu={loginMenu}
            logo="https://cdn-dev.print-marketplace.co.uk/frontend/pmp-ccs-logo.svg"
            logoLink="/"
          />
        </div>
      </div>
      <div className="container">
        <Hero
          jumbotron="The simple way to save on public sector printing"
          lead="Compare printing prices from trusted suppliers – quickly and simply.
          Then easily track your print-projects right through to delivery."
          hasVideo={false}
          hasRegisterNow={false}
          hasRequestADemo
          hasCreateAccount
        />
      </div>
    </section>

    <section className="container-fluid pb-3">
      <div className="container home-hero-lift">
        <Benefits benefits={buyerBenefits} />

        <Awards />
      </div>
    </section>

    <section className="container-fluid pb-6">
      <HowItWorks
        howItWorks={buyerHowItWorks}
        intro="Simple, effective and quick - get the job done, whatever your sector"
        hasRegisterNow={false}
        hasRequestADemo={false}
        hasSeeHowItWorks
        video="https://player.vimeo.com/video/484398245?autoplay=1&loop=1&autopause=0"
        theme="primary"
      />
    </section>

    <section className="container-fluid bg--white pb-6">
      <div className="container">
        <Highlights />

        <TriedTested triedTested={buyerTriedTested} />
      </div>
    </section>

    <section className="container-fluid bg--white pb-6">
      <div className="container">
        <BuyerAccount subTitle="To register please create your buyer account with CCS." />
      </div>
    </section>

    <Footer
      sections={footerContent}
      partners={footerPartnersCCSContent}
      year={`${new Date().getFullYear()}`}
    />
  </Layout>
);

export default IndexPage;
