import { CardContentModel } from '../models';

export const buyerBenefits: CardContentModel[] = [
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_big_easy.svg',
    title: 'Easy and intuitive',
    description:
      'Guides you step-by-step through your entire print-buying journey',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_big_design.svg',
    title: 'Design your way',
    description:
      'From beginner to expert – supported by our talented designers or via a simple online self-design tool',
  },
  {
    icon: 'https://cdn-dev.print-marketplace.co.uk/frontend/icon_big_advise.svg',
    title: 'Sector-specific expert advice',
    description:
      'Live chat functionality – our print experts are always on hand to assist you',
  },
];
