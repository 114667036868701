import React from 'react';

const Awards = () => (
  <div className="pmp-card text-center bg--inactive border-0">
    <div className="card-body p-1">
      <img
        src="https://cdn-dev.print-marketplace.co.uk/frontend/marketing/cips-awards-finalist.png"
        alt="Excellence in Procurement"
        className="col-sm-5 col-md-3"
      />
      <p className="lead">
        Proud to be a finalist in the category - Best initiative to build a
        diverse supplier base.
      </p>
    </div>
  </div>
);

export default Awards;
